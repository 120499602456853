"use client";

import { useRouter } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import type { FaqItemFragmentFragment } from "@commerce-frontend/types";
import { graphql } from "@commerce-frontend/types";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { useLocale } from "next-intl";
import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { RichText } from "~/components/cms/RichText/RichText";

graphql(/* GraphQL */ `
	fragment FAQItemFragment on FAQItem {
		__typename
		id
		slug
		title

		richContent: content {
			...RichTextFragment
		}
	}
`);

export const FAQItem = forwardRef<
	HTMLDivElement,
	{
		data: FaqItemFragmentFragment;
		className?: string;
	}
>(({ data, className, ...props }, ref) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);
	const [open, setOpen] = useState(false);
	const router = useRouter();
	const locale = useLocale() as Locale;

	useLayoutEffect(() => {
		setOpen(`#${data.slug}` === window.location.hash);
	}, [data.slug]);

	useEffect(() => {
		const clientHeight = contentRef.current?.clientHeight;
		if (clientHeight && clientHeight > 0) {
			setContentHeight(clientHeight);
		}
	}, [data.richContent, setContentHeight]);

	const toggle = useCallback(() => {
		if (!open) {
			router.push(`#${data.slug}` as "/", {
				scroll: false,
				locale,
			});
		}
		setOpen(!open);
	}, [open, setOpen, router, data.slug]);

	if (!data.richContent?.content) return null;

	return (
		<div ref={ref} {...props} className={cn("w-full flex flex-col", className)}>
			<button
				id={data.slug ?? ""}
				className="select-none appearance-none overflow-hidden text-wrap rounded-none pb-3xl pl-0.5 pr-2 text-left text-lg font-medium text-gray-900"
				onClick={toggle}
			>
				<Icon
					icon="chevron-down"
					className={cn(
						"relative float-right ml-2 bg-brand-500 transition-transform",
						open && "-rotate-180",
					)}
					size="md"
				/>
				{data.title}
			</button>
			<div
				className="overflow-hidden transition-[max-height]"
				style={{
					maxHeight: open ? contentHeight : 0,
				}}
			>
				{data.richContent?.content && (
					<div className="flex flex-col gap-4 pr-4xl pb-3xl" ref={contentRef}>
						<RichText data={data.richContent} locale={locale} />
					</div>
				)}
			</div>
		</div>
	);
});
FAQItem.displayName = "FAQItem";
