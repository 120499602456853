import type { Address, Customer } from "@commercetools/platform-sdk";
import { getLocalizedValue as _getLocalizedValue } from "@labdigital/toolkit";

/**
 * getLocalizedValue wraps the verison from @labdigital/toolkit to set the
 * fallback locale used in this application.
 */
export const getLocalizedValue = <T = string>(
	values: { [key: string]: T } | undefined,
	locale: string,
): T | undefined => (values ? _getLocalizedValue<T>(values, locale, ["en"]) : undefined);

/**
 * get default shipping or billing address of a commercetools customer
 */
export const getDefaultAddress = (
	customer: Customer,
	key: keyof Pick<Customer, "defaultBillingAddressId" | "defaultShippingAddressId">,
): Address | undefined => {
	return customer.addresses.find((address) => address.id && address.id === customer[key]);
};

/**
 * Transforms a Storyblok URL according to these rules:
 * 1. If the URL is absolute (http:// or https://), return it unchanged.
 * 2. Otherwise, look for an initial locale of the form xx-XX (2 letters, dash, 2 letters).
 *    - If found, use that as the locale (lowercased).
 *    - Otherwise, default to 'nl-nl'.
 * 3. After extracting (or defaulting) to the locale, remove either "pages" or a 2-letter segment
 *    from the start of the path (if present).
 * 4. Rebuild the path as "/<locale>/<remainingSegments>[/]"
 *    (preserving the trailing slash if it existed).
 *
 * Examples:
 *   "/pages/test/"        -> "/nl-nl/test/"
 *   "/de-DE/pages/test/"  -> "/de-de/test/"
 *   "/NL/test/"           -> "/nl-nl/test/"
 *   "/de-DE/NL/test/"     -> "/de-de/test/"
 *   "https://example.com" -> "https://example.com" (unchanged)
 */
export const parseStoryblokUrl = (
	inputUrl: string,
	preferedLocale: string | undefined = undefined,
): string => {
	// 1) If the URL is absolute (starts with http:// or https://), return it unchanged
	if (/^https?:\/\//i.test(inputUrl)) {
		return inputUrl;
	}

	const endsWithSlash = inputUrl.endsWith("/");
	let trimmed = inputUrl.replace(/^\/+/, "");

	// default locale
	let locale = "nl-nl";
	const localePattern = /^([a-zA-Z]{2}-[a-zA-Z]{2})(\/|$)/; // e.g. de-DE, az-AZ, en-US, etc.

	const localeMatch = trimmed.match(localePattern);
	if (localeMatch) {
		locale = localeMatch[1].toLowerCase(); // e.g. 'de-de'
		// Remove the matched locale from the start of the path
		trimmed = trimmed.substring(localeMatch[1].length).replace(/^\/+/, "");
	}

	// 3) After extracting locale, remove "pages" or 2-letter segment from the start if found
	const segments: string[] = trimmed.split("/").filter(Boolean); // remove empty elements

	if (segments.length > 0) {
		const firstSegment = segments[0];
		// Check if it's "pages" or a 2-letter code
		if (firstSegment.toLowerCase() === "pages" || /^[a-zA-Z]{2}$/.test(firstSegment)) {
			segments.shift(); // remove that segment
		}
	}

	// Re-join the remaining path segments
	const newPath = segments.join("/");
	const finalLocale = preferedLocale?.toLowerCase() ?? locale;

	// 4) Build final path => "/<locale>/<rest>[/]"
	let finalUrl = `/${finalLocale}/${newPath}`;

	// Preserve trailing slash if the original input had one
	if (endsWithSlash && !finalUrl.endsWith("/")) {
		finalUrl += "/";
	}

	return finalUrl;
};

/**
 * Extracts the AV SKU from a product slug.
 */
export const getSkuFromSlug = (slug: string): string | null => {
	const slugParts = slug
		.split("-")
		.filter((slugPart) => /* regex that matches only numbers*/ slugPart.match(/^\d+$/));

	if (slugParts.length === 0) {
		return null;
	}

	return slugParts.join("-");
};
