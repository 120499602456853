import type { Locale } from "@commerce-frontend/i18n/types";
import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import { graphql } from "@commerce-frontend/types";
import { typography } from "@commerce-frontend/ui/base/Typography/typography";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { parseStoryblokUrl } from "@commerce-shared/utility";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { captureException } from "@sentry/nextjs";
import Link from "next/link";
import type { HTMLAttributes } from "react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";

export const RichTextFragment = graphql(/* GraphQL */ `
	fragment RichTextFragment on RichText {
		__typename
		id
		content
	}
`);

type RichTextBlockProps = {
	data: ResultOf<typeof RichTextFragment>;
	contentClassName?: HTMLAttributes<HTMLDivElement>["className"];
	locale: Locale;
} & HTMLAttributes<HTMLDivElement>;

export const RichText = ({ className, contentClassName, data, locale }: RichTextBlockProps) => (
	<div className={cn("max-w-3xl", className)} {...storyblokDataAttributes(data)}>
		{data.content && (
			<div className={cn("prose max-w-full", contentClassName)}>
				{render(JSON.parse(data.content), {
					markResolvers: {
						[MARK_LINK]: (children, props) => {
							const { linktype, href, target } = props;
							if (!href) {
								captureException(new Error("empty href in storyblok rich text"));
							}

							if (linktype === "email") {
								// Email links: add `mailto:` scheme and map to <a>
								return <a href={`mailto:${href}`}>{children}</a>;
							}

							if (href?.match(/^(https?:)?\/\//)) {
								// External links: map to <a>
								return (
									<a href={href} target={target}>
										{children}
									</a>
								);
							}

							const normalizedHref = parseStoryblokUrl(href ?? "", locale as string);

							return (
								<Link href={normalizedHref} target={target}>
									{children}
								</Link>
							);
						},
					},
					blokResolvers: {
						lead_text: (props) => <p className={typography("lead")}>{props.content as string}</p>,
					},
				})}
			</div>
		)}
	</div>
);
