"use client";

import { cn } from "@commerce-frontend/ui/helpers/styles";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import { useTranslations } from "next-intl";
import Image from "next/image";
import * as React from "react";
import { Button } from "../../base/Button/Button";
import { Icon } from "../../base/Icon/Icon";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
	opts?: CarouselOptions;
	plugins?: CarouselPlugin;
	orientation?: "horizontal" | "vertical";
	setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
	carouselRef: ReturnType<typeof useEmblaCarousel>[0];
	api: ReturnType<typeof useEmblaCarousel>[1];
	scrollPrev: () => void;
	scrollNext: () => void;
	canScrollPrev: boolean;
	canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
	const context = React.useContext(CarouselContext);

	if (!context) {
		throw new Error("useCarousel must be used within a <Carousel />");
	}

	return context;
}

const Carousel = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(({ orientation = "horizontal", opts, setApi, plugins, className, children, ...props }, ref) => {
	const [carouselRef, api] = useEmblaCarousel(
		{
			...opts,
			axis: orientation === "horizontal" ? "x" : "y",
		},
		plugins,
	);
	const [canScrollPrev, setCanScrollPrev] = React.useState(false);
	const [canScrollNext, setCanScrollNext] = React.useState(false);

	const onSelect = React.useCallback((api: CarouselApi) => {
		if (!api) {
			return;
		}

		setCanScrollPrev(api.canScrollPrev());
		setCanScrollNext(api.canScrollNext());
	}, []);

	const scrollPrev = React.useCallback(() => {
		api?.scrollPrev();
	}, [api]);

	const scrollNext = React.useCallback(() => {
		api?.scrollNext();
	}, [api]);

	const handleKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === "ArrowLeft") {
				event.preventDefault();
				scrollPrev();
			} else if (event.key === "ArrowRight") {
				event.preventDefault();
				scrollNext();
			}
		},
		[scrollPrev, scrollNext],
	);

	React.useEffect(() => {
		if (!api || !setApi) {
			return;
		}

		setApi(api);
	}, [api, setApi]);

	React.useEffect(() => {
		if (!api) {
			return;
		}

		onSelect(api);
		api.on("reInit", onSelect);
		api.on("select", onSelect);

		return () => {
			api?.off("select", onSelect);
		};
	}, [api, onSelect]);

	return (
		<CarouselContext.Provider
			value={{
				carouselRef,
				api: api,
				opts,
				orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
				scrollPrev,
				scrollNext,
				canScrollPrev,
				canScrollNext,
			}}
		>
			<div
				ref={ref}
				onKeyDownCapture={handleKeyDown}
				className={cn("relative group/carousel", className)}
				role="region"
				aria-roledescription="carousel"
				{...props}
			>
				{children}
			</div>
		</CarouselContext.Provider>
	);
});
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const { carouselRef, orientation } = useCarousel();

		return (
			<div ref={carouselRef} className="overflow-hidden p-[2px]">
				<div
					ref={ref}
					className={cn("flex gap-xl", orientation === "vertical" && "flex-col", className)}
					{...props}
				/>
			</div>
		);
	},
);
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				role="group"
				aria-roledescription="slide"
				className={cn("min-w-0 shrink-0 grow-0 basis-full", className)}
				{...props}
			/>
		);
	},
);
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
	({ className, ...props }, ref) => {
		const { orientation, scrollPrev, canScrollPrev } = useCarousel();
		const t = useTranslations("Carousel");

		return (
			<Button
				ref={ref}
				onClick={scrollPrev}
				variant="secondary"
				aria-label={t("previous")}
				disabled={!canScrollPrev}
				className={cn(
					"hidden absolute size-14 opacity-0 group-hover/carousel:opacity-100 transition-opacity lg:flex",
					orientation === "horizontal"
						? "left-0 top-1/2 -translate-y-1/2 lg:left-xl"
						: "-top-12 left-1/2 -translate-x-1/2 rotate-90",
					!canScrollPrev && "!hidden",
					className,
				)}
				{...props}
			>
				<Icon icon="arrow-left" className="size-6 bg-gray-600" />
			</Button>
		);
	},
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
	({ className, ...props }, ref) => {
		const { orientation, scrollNext, canScrollNext } = useCarousel();
		const t = useTranslations("Carousel");

		return (
			<Button
				ref={ref}
				onClick={scrollNext}
				variant="secondary"
				aria-label={t("next")}
				disabled={!canScrollNext}
				className={cn(
					"hidden absolute size-14 opacity-0 group-hover/carousel:opacity-100 transition-opacity lg:flex",
					orientation === "horizontal"
						? "right-0 top-1/2 -translate-y-1/2 lg:right-xl"
						: "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
					!canScrollNext && "!hidden",
					className,
				)}
				{...props}
			>
				<Icon icon="arrow-right" className="size-6 bg-gray-600" />
			</Button>
		);
	},
);
CarouselNext.displayName = "CarouselNext";

const CarouselDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className }, ref) => {
		const t = useTranslations("Carousel");
		const { api } = useCarousel();
		const [current, setCurrent] = React.useState(0);
		const [count, setCount] = React.useState(0);

		const calcPagination = (api: CarouselApi) => {
			if (!api) {
				return;
			}

			setCount(api.scrollSnapList().length);
			setCurrent(api.selectedScrollSnap() + 1);
			api.on("select", () => {
				setCurrent(api.selectedScrollSnap() + 1);
			});
		};

		const handleReInit = () => {
			calcPagination(api);
		};

		React.useEffect(() => {
			if (!api) {
				return;
			}

			calcPagination(api);

			api.on("reInit", handleReInit);

			return () => {
				api.off("reInit", handleReInit);
			};
		}, [api]);

		if (!count) return null;

		return (
			<div ref={ref} className={cn("flex justify-center", className)}>
				<div className="flex gap-xl rounded-xs px-xl py-lg">
					{Array.from(Array(count).keys()).map((i) => (
						<Button
							key={`${i}-${count}`}
							onClick={() => api?.scrollTo(i)}
							aria-label={t("goTo", { slide: i + 1 })}
							className={cn(
								"size-2 rounded-xxs",
								i === current - 1 ? "w-8 bg-orange-dark-200" : "bg-black/20",
							)}
							variant="link"
						/>
					))}
				</div>
			</div>
		);
	},
);

CarouselDots.displayName = "CarouselDots";

const CarouselThumbnail = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		image: { alt?: string | null; url: string };
		isSelected: boolean;
		index: number;
		onClick?: () => void;
	}
>(({ image, isSelected, index, onClick, className }, ref) => {
	const t = useTranslations("Carousel");
	return (
		<div ref={ref}>
			<button
				type="button"
				className={cn(
					"group relative h-40 w-32 overflow-hidden rounded-xs border border-gray-200 shadow-1 transition-all hover:shadow-2",
					"before:absolute before:left-0 before:top-0 before:h-[3px] before:w-full before:bg-transparent before:transition-all before:content-['']",
					isSelected && "shadow-0 before:bg-orange-dark-200",
					className,
				)}
				aria-label={t("view-image", { count: index + 1 })}
				onClick={onClick}
			>
				<Image src={image.url} alt="" width="128" height="160" className="w-full" />
			</button>
		</div>
	);
});

CarouselThumbnail.displayName = "CarouselThumbnail";

const CarouselThumbnails = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		images: { url: string; alt?: string | null }[];
	}
>(({ images, className }, ref) => {
	const { api } = useCarousel();
	const [current, setCurrent] = React.useState(0);

	const calcPagination = React.useMemo(
		() => (api: CarouselApi) => {
			if (!api) {
				return;
			}

			setCurrent(api.selectedScrollSnap());

			api.on("select", () => {
				setCurrent(api.selectedScrollSnap());
			});
		},
		[setCurrent],
	);

	const handleReInit = React.useMemo(
		() => () => {
			calcPagination(api);
		},
		[calcPagination, api],
	);

	React.useEffect(() => {
		if (!api) {
			return;
		}

		handleReInit();

		api.on("reInit", handleReInit);

		return () => {
			api.off("reInit", handleReInit);
		};
	}, [api, handleReInit]);

	return (
		<div ref={ref} className={cn("flex w-full flex-wrap gap-md p-md", className)}>
			{images.map((image, i) => (
				<CarouselThumbnail
					key={image?.url}
					index={i}
					image={image}
					isSelected={i === current}
					onClick={() => api?.scrollTo(i)}
				/>
			))}
		</div>
	);
});

CarouselThumbnails.displayName = "CarouselThumbnails";

export {
	Carousel,
	CarouselContent,
	CarouselDots,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
	CarouselThumbnails,
	type CarouselApi,
};
